import React from "react";
import Navbar from "../Navbar";
import AboutHeroSection from "../AboutSections/AboutHeroSection";
import Footer from "../Footer";
import EmergencyContact from "../EmergencyContact";
import { useNavigate } from "react-router-dom";

const Appointment = () => {
  const navigate = useNavigate();

  function handlePhysicalAppointment() {
    navigate("/appointment-with-doctor/physical");
  }

  function handleOnlineAppointment() {
    navigate("/appointment-with-doctor/online");
  }

  return (
    <>
      <Navbar />
      <AboutHeroSection img={"about_hero_bg"} h2={"Book Your Appointment"} />
      <div className="min-h-screen bg-gray-100 py-12">
        <div className="w-full max-w-6xl mx-auto px-4">
          {/* Section Title */}
          <h2 className="text-3xl font-bold text-center text-green-600 mb-8">
            Choose Your Appointment Type
          </h2>
          <p className="text-center text-gray-600 mb-12">
            Whether you prefer visiting us in person or consulting online, we’ve got you covered! Explore your options below.
          </p>

          {/* Physical and Online Appointment Section */}
          <div className="flex flex-col  md:flex-row justify-between gap-8">
            {/* Physical Appointment */}
            <div className="flex-1 bg-white shadow-lg rounded-lg overflow-hidden h-full">
              <img
                src={'/assets/Appointment/physical-appointment.webp'}
                alt="Physical Appointment"
                className="w-full h-[22rem] object-cover"
              />
              <div className="p-6 flex flex-col justify-between">
                <h3 className="text-xl font-bold text-gray-800 mb-4">
                  Physical Appointment
                </h3>
                <p className="text-gray-600 mb-6">
                  Visit our clinic and meet our expert doctors in person. Experience personalized and compassionate care.
                </p>
                <button
                  className="w-full bg-green-600 text-white py-2 rounded hover:bg-green-700 transition duration-200"
                  onClick={handlePhysicalAppointment}
                >
                  Book Physical Appointment
                </button>
              </div>
            </div>

            {/* Online Appointment */}
            <div className="flex-1 bg-white shadow-lg rounded-lg overflow-hidden h-full">
              <img
                src={'/assets/Appointment/online-appointment.webp'}
                alt="Online Appointment"
                className="w-full h-[22rem] object-cover"
              />
              <div className="p-6 flex flex-col justify-between">
                <h3 className="text-xl font-bold text-gray-800 mb-4">
                  Online Appointment
                </h3>
                <p className="text-gray-600 mb-6">
                  Consult with our doctors from the comfort of your home via secure video call.
                </p>
                <button
                  className="w-full bg-green-600 text-white py-2 rounded hover:bg-green-700 transition duration-200"
                  onClick={handleOnlineAppointment}
                >
                  Book Online Appointment
                </button>
              </div>
            </div>
          </div>


          {/* Testimonials Section */}
          <div className="mt-16">
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-8">
              What Our Patients Say
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[{
                img: '/assets/Appointment/patient-1.webp',
                name: "Muhammad Ali",
              }, {
                img: '/assets/Appointment/patient-2.webp',
                name: "Talha Ahmed",
              }, {
                img: '/assets/Appointment/patient-3.webp',
                name: "Sara Khan",
              }].map((img, index) => (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-lg p-6 text-center"
                >
                  <img
                    src={img.img}
                    alt={`Patient ${index + 1}`}
                    className="mx-auto mb-4 rounded-full w-20 h-20"
                  />
                  <p className="text-gray-600 italic">
                    "The care I received was exceptional! Booking an appointment was so easy and hassle-free."
                  </p>
                  <h4 className="mt-4 font-bold text-gray-800">
                    - {img.name}
                  </h4>
                </div>
              ))}
            </div>
          </div>

          {/* FAQ Section */}
          <div className="mt-16">
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-8">
              Frequently Asked Questions
            </h2>
            <div className="space-y-4">
              <div className="bg-white shadow-lg rounded-lg p-4">
                <h3 className="font-semibold text-gray-800 mb-2">
                  How can I book an appointment?
                </h3>
                <p className="text-gray-600">
                  You can book an appointment by selecting your preferred type of consultation and filling out the booking form.
                </p>
              </div>
              <div className="bg-white shadow-lg rounded-lg p-4">
                <h3 className="font-semibold text-gray-800 mb-2">
                  Are online appointments secure?
                </h3>
                <p className="text-gray-600">
                  Yes, all online consultations are conducted over secure and private video calls.
                </p>
              </div>
              <div className="bg-white shadow-lg rounded-lg p-4">
                <h3 className="font-semibold text-gray-800 mb-2">
                  Can I reschedule or cancel an appointment?
                </h3>
                <p className="text-gray-600">
                  Absolutely! You can reschedule or cancel your appointment by contacting us at least 24 hours in advance.
                </p>
              </div>
            </div>
          </div>

          {/* Need Assistance Section */}
          <div className="mt-16 bg-green-50 py-12 px-1 rounded-lg flex flex-col lg:flex-row items-center gap-8">
            {/* Image */}
            <div className="flex-shrink-0 w-full lg:w-1/2">
              <img
                src="/assets/Appointment/customer-service.png"
                alt="Need Assistance"
                className="w-full h-auto rounded-lg"
              />
            </div>

            {/* Content */}
            <div className="lg:w-1/2 text-center lg:text-left">
              <h2 className="text-2xl font-bold text-green-600 mb-4">
                Need Assistance?
              </h2>
              <p className="text-gray-600 mb-6">
                Have more questions or need help with booking your appointment? Our friendly support team is here to assist you every step of the way. Contact us today!
              </p>
              <div >
                <EmergencyContact />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Appointment;
