import React from 'react'
import { Link } from 'react-router-dom'

function EmergencyContact() {
    return (
        <>

            <div className="bg-white-200 py-10">
                <div className="max-w-7xl mx-auto grid md:grid-cols-2 gap-8 text-center px-4">
                    {/* Booking Appointment Section */}
                    <div className="bg-[#219dbc27] text-gray-600 py-8 px-6 rounded-md shadow-md">
                        <h2 className="text-2xl font-bold mb-4 ">Our Support</h2>
                        <p className="text-lg  font-medium">With Our Consultants</p>
                        <a
                            href="tel:+923095990667"
                            className="block text-2xl  font-bold mt-4 hover:underline"
                        >
                            +923095990667
                        </a>
                    </div>

                    {/* Emergency Section */}
                    <div className="bg-[#219dbc27] text-gray-600 py-8 px-6 rounded-md shadow-md">
                        <h2 className="text-2xl font-bold mb-4">Emergency</h2>
                        <p className="text-lg font-medium">Psychiatric Care 24/7</p>
                        <a
                            href="tel:+923305844111"
                            className="block text-2xl font-bold mt-4 hover:underline"
                        >
                            +923305844111
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EmergencyContact