import { useRef, useState } from "react";
import AboutHeroSection from "../AboutSections/AboutHeroSection";
import Footer from "../Footer";
import Card from "../homepageSections/Card";
import Navbar from "../Navbar";
import blogsData from "./blogData";
import DOMPurify from "dompurify";
import Pagination from "./Pagination";
import EmergencyContact from "../EmergencyContact";

const limitWords = (text, wordLimit) => {
  const words = text.split(" ");
  return words.length > wordLimit
    ? words.slice(0, wordLimit).join(" ") + "..."
    : text;
};

const AllBlogs = () => {
  const itemsPerPage = 8; // Define the number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const cardSectionRef = useRef(null); // Ref for the blog card section

  // Scroll helper function
  const scrollToSection = () => {
    if (cardSectionRef.current) {
      console.log("Scrolling to:", cardSectionRef.current); // Debugging
      cardSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      console.error("cardSectionRef is null");
    }
  };

  // Calculate which blogs to show on the current page
  const indexOfLastBlog = currentPage * itemsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - itemsPerPage;
  const currentBlogs = blogsData.slice(indexOfFirstBlog, indexOfLastBlog);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    scrollToSection(); // Scroll to the blog card section
  };

  return (
    <>
      <Navbar />
      <AboutHeroSection img={"about_hero_bg"} h2={"All Blogs"} />
      {/* Blog Cards */}
      <section
        ref={cardSectionRef} // Add ref to this section
        className="blog-cards flex flex-wrap items-center justify-center gap-4 pt-24 px-4"
      >
        {currentBlogs.map((blog, index) => {
          const sanitizedContent = DOMPurify.sanitize(blog.content);
          return (
            <Card
              key={`blog-${index}`}
              title={blog.title}
              para={limitWords(sanitizedContent, 45)}
              animation={index % 2 === 0 ? "fade-down" : "fade-up"}
              type="blog"
              serviceLink={blog.id}
            />
          );
        })}
      </section>

      {/* Pagination */}
      <EmergencyContact />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={blogsData.length}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
      <Footer />
    </>
  );
};

export default AllBlogs;
