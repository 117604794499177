import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import blogsData from "./blogData";
import AboutHeroSection from "../AboutSections/AboutHeroSection";
import Navbar from "../Navbar";
import "../../scss/HeroSection.scss"; // Import SCSS file
import Footer from "../Footer";
import ContactForm from "../ContactSections/ContactMiddleSections";

const BlogPage = () => {
  const navigate = useNavigate();

  const handleScrollToVision = (route, id) => {
    navigate(route);
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const { blogId } = useParams();
  const blog = blogsData.find((blog) => blog.id === blogId);

  if (!blog) {
    return <div className="blog-not-found">Blog not found!</div>;
  }

  return (
    <>
      <div data-aos="fade-up">
        <Navbar />
        <AboutHeroSection img={"about_hero_bg"} h2={blog.title} />
      </div>
      <div className="blog-container">
        <h1 className="blog-title" data-aos="fade-out">
          <h2>{blog.title}</h2>
        </h1>
        <div
          className="blog-content"
          data-aos="zoom-in"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        />
        <div className="blog-service" data-aos="fade-up">
          <h3>{blog.service}</h3>
          <p>
            Join our workshops and seminars to learn strategies for mental
            well-being, stress management, and emotional resilience. Contact us
            to register.
          </p>
        </div>

        <div>
        <h1 className="text-3xl font-bold flex items-center justify-center pt-20">
          Book Appointment
        </h1>
        <ContactForm />
      </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogPage;
