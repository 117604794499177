import React, { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { collection, getDocs, addDoc } from "firebase/firestore";
import Navbar from "../Navbar";
import AboutHeroSection from "../AboutSections/AboutHeroSection";
import Footer from "../Footer";
import EmergencyContact from "../EmergencyContact";
import FAQs from "../FAQs";

const PhysicalAppointment = () => {
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    phone: "",
    email: "",
    consultant: "",
    address: "",
    date: "",
    time: "",
    note: "",
  });
  const dropdownItems = [
    {
      title: 'How can I book an appointment?',
      content: 'You can book an appointment by selecting your preferred type of consultation and filling out the booking form.'
    },
    {
      title: 'What can I expect during my first session?',
      content: 'In your first session, your therapist will get to know you and your goals for therapy, and you’ll work together to develop a plan.'
    },
    {
      title: 'What should I do if I can’t attend a session?',
      content: 'If you need to cancel or reschedule, it’s important to notify your therapist at least 24 hours in advance.'
    },
    {
      title: 'Are my sessions confidential?',
      content: 'Yes, therapy sessions are confidential with a few legal exceptions such as the risk of harm to yourself or others.'
    }
  ];

  const formFields = [
    { name: "name", type: "text", label: "Full Name", placeholder: "Your Name", required: true },
    { name: "age", type: "number", label: "Age", placeholder: "Your Age", required: true },
    { name: "phone", type: "text", label: "Phone Number", placeholder: "Your Phone Number", required: true },
    { name: "email", type: "email", label: "Email", placeholder: "Your Email Address", required: true },
    { name: "address", type: "text", label: "Address", placeholder: "Your Address", required: true },
  ];

  const [consultants, setConsultants] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState({});
  const [loadingConsultants, setLoadingConsultants] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    const fetchConsultants = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "doctors"));
        const docs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setConsultants(docs);
      } catch (error) {
        console.error("Error fetching consultants: ", error);
      } finally {
        setLoadingConsultants(false); // Stops loading after fetching
      }
    };

    fetchConsultants();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "date" && selectedSlots) {
      const dayOfWeek = new Date(value).toLocaleDateString("en-US", {
        weekday: "long",
      });
      const times = selectedSlots[dayOfWeek] || [];
      setAvailableTimes(times);
    }
  };

  const handleConsultantChange = (e) => {
    const selectedConsultant = consultants.find(
      (consultant) => consultant.name === e.target.value
    );

    if (selectedConsultant) {
      setSelectedSlots(selectedConsultant.physicalSlots || {});
    } else {
      setSelectedSlots({});
    }

    setAvailableTimes([]);
    setFormData((prev) => ({
      ...prev,
      consultant: e.target.value,
      date: "",
      time: "",
    }));
  };
  const popupNotification = () => {
    return (
      <div  className="fixed top-0 left-0 w-full z-50 h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
        <div data-aos="zoom-in" className="bg-white p-8 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Appointment Booked Successfully!
          </h2>
          <p className="text-gray-600">
            Your appointment has been successfully booked. You will receive a confirmation email shortly.
          </p>
          <button
            onClick={() => setFormData({}) || setPopup(false)}
            className="mt-4 px-4 py-2 bg-[#219ebc] text-white font-semibold rounded-lg hover:bg-[#5ac3dd] focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Close
          </button>
        </div>
      </div>
    )
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);
    try {
      await addDoc(collection(db, "physicalAppointments"), formData);
      setFormData({
        name: "",
        age: "",
        phone: "",
        email: "",
        consultant: "",
        date: "",
        time: "",
        note: "",
      });
    } catch (error) {
      alert("Error booking appointment: " + error.message);
    } finally {
      setLoadingSubmit(false);
      setPopup(true);
    }
  };


  return (
    <>
      <Navbar />
      <AboutHeroSection
        img={"physical-appointment"}
        h2={"Book Your Physical Appointment"}
      />
      {popup && popupNotification()}
      {/* Main Container */}
      <div className="w-full max-w-7xl flex flex-col items-center mx-auto px-4  min-h-screen py-10">
        <div className="w-full max-w-4xl bg-white shadow-2xl rounded-lg p-8">
          <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">
            Book Your Phsyical Consultation
          </h2>
          <p className="text-center mb-2 text-gray-600">
            Schedule your physical appointment today with our professionals.
          </p>
          {loadingConsultants ? (
            <div className="flex justify-center items-center py-8">
              <div className="animate-spin h-8 w-8 border-4 border-blue-300 border-t-transparent rounded-full"></div>
            </div>
          ) : (

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Consultant Selection */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2" htmlFor="consultant">
                    Consultant
                  </label>
                  <select
                    name="consultant"
                    value={formData.consultant}
                    onChange={handleConsultantChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select Consultant</option>
                    {consultants.map((consultant) => (
                      <option key={consultant.id} value={consultant.name}>
                        {consultant.name}
                      </option>
                    ))}
                  </select>
                </div>

                {formFields.map((field) => (
                  <div key={field.name}>
                    <label className="block text-gray-700 font-medium mb-2" htmlFor={field.name}>
                      {field.label}
                    </label>
                    <input
                      name={field.name}
                      type={field.type}
                      value={formData[field.name] || ""}
                      onChange={handleChange}
                      required={field.required}
                      placeholder={field.placeholder}
                      disabled={field.disabled ? field.disabled(formData) : false}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                ))}

                {/* Date */}
                <div className="relative group">
                  <label className="block text-gray-700 font-medium mb-2" htmlFor="date">
                    Date
                  </label>
                  <input
                    name="date"
                    type="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                    disabled={!formData.consultant}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {/* Tooltip */}
                  {!formData.consultant && (
                    <div className="absolute top-20 left-20 hidden group-hover:block w-48 bg-gray-800 text-white text-sm p-2 rounded-md shadow-lg">
                      Please select a consultant first.
                    </div>
                  )}
                </div>

                {/* Time */}
                <div className="relative group">
                  <label className="block text-gray-700 font-medium mb-2" htmlFor="time">
                    Time
                  </label>
                  <select
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                    required
                    disabled={!formData.date}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select Time</option>
                    {availableTimes.map((slot) => (
                      <option key={slot.id} value={`${slot.start} - ${slot.end}`}>
                        {slot.start} - {slot.end}
                      </option>
                    ))}
                    {availableTimes.length === 0 && (
                      <option value="">No slots available</option>
                    )}
                  </select>
                  {/* Tooltip */}
                  {!formData.date && (
                    <div className="absolute top-20 left-20 hidden group-hover:block w-48 bg-gray-800 text-white text-sm p-2 rounded-md shadow-lg">
                      Please select a date first.
                    </div>
                  )}
                </div>

              </div>

              {/* Note */}
              <div>
                <label className="block text-gray-700 font-medium mb-2" htmlFor="note">
                  Message
                </label>
                <textarea
                  name="note"
                  value={formData.note}
                  onChange={handleChange}
                  rows="4"
                  placeholder="Your Message..."
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Submit Button */}
              <div className="text-center">
                <button
                  type="submit"
                  className="w-full px-4 py-2 bg-[#219ebc] text-white font-semibold rounded-lg hover:bg-[#5ac3dd] focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={loadingSubmit}
                >
                  {loadingSubmit ? (
                    <><div className="inline-block animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full mr-2"></div>
                      Booking...</>
                  ) : (
                    "Book Appointment"
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
        {/* Testimonials Section */}
        <div className="mt-16">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-8">
            What Our Patients Say
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[{
              img: '/assets/Appointment/patient-1.webp',
              name: "Muhammad Ali",
            }, {
              img: '/assets/Appointment/patient-2.webp',
              name: "Talha Ahmed",
            }, {
              img: '/assets/Appointment/patient-3.webp',
              name: "Sara Khan",
            }].map((img, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg p-6 text-center"
              >
                <img
                  src={img.img}
                  alt={`Patient ${index + 1}`}
                  className="mx-auto mb-4 rounded-full w-20 h-20"
                />
                <p className="text-gray-600 italic">
                  "The care I received was exceptional! Booking an appointment was so easy and hassle-free."
                </p>
                <h4 className="mt-4 font-bold text-gray-800">
                  - {img.name}
                </h4>
              </div>
            ))}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="mt-16 shadow-xl">
          <FAQs isFAQImage={false} dropdownItems={dropdownItems} />
        </div>

        {/* Need Assistance Section */}
        <div className="mt-16 bg-green-50 py-12 px-1 rounded-lg flex flex-col lg:flex-row items-center gap-8">
          {/* Image */}
          <div className="flex-shrink-0 w-full lg:w-1/2">
            <img
              src="/assets/Appointment/customer-service.png"
              alt="Need Assistance"
              className="w-full h-auto rounded-lg"
            />
          </div>

          {/* Content */}
          <div className="lg:w-1/2 text-center lg:text-left">
            <h2 className="text-2xl font-bold text-green-600 mb-4">
              Need Assistance?
            </h2>
            <p className="text-gray-600 mb-6">
              Have more questions or need help with booking your appointment? Our friendly support team is here to assist you every step of the way. Contact us today!
            </p>
            <div >
              <EmergencyContact />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PhysicalAppointment;